import { default as _91_46_46_46token_93E51lbisvWWMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/authorization-social/[...token].vue?macro=true";
import { default as indexsqeiX8pM52Meta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/authorization/index.vue?macro=true";
import { default as indexIA14BUwnugMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/api/index.vue?macro=true";
import { default as index2NNaIA0Y3LMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/index.vue?macro=true";
import { default as indexEV5VESRwcHMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/numbers-history/index.vue?macro=true";
import { default as indexi1rIPbNh7RMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/payments/index.vue?macro=true";
import { default as index6FkM4pJ9IEMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/profile/index.vue?macro=true";
import { default as indexohVdntkSTPMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/forgot-password/index.vue?macro=true";
import { default as index6eej0h6p7JMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/index.vue?macro=true";
import { default as index8vIkxF97sXMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/payment/result/index.vue?macro=true";
import { default as indexYF625XkeFQMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/registration/index.vue?macro=true";
import { default as _91_46_46_46token_93eDOYFhPg1BMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/reset-password/[...token].vue?macro=true";
export default [
  {
    name: _91_46_46_46token_93E51lbisvWWMeta?.name ?? "authorization-social-token___en___default",
    path: _91_46_46_46token_93E51lbisvWWMeta?.path ?? "/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93E51lbisvWWMeta || {},
    alias: _91_46_46_46token_93E51lbisvWWMeta?.alias || [],
    redirect: _91_46_46_46token_93E51lbisvWWMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93E51lbisvWWMeta?.name ?? "authorization-social-token___en",
    path: _91_46_46_46token_93E51lbisvWWMeta?.path ?? "/en/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93E51lbisvWWMeta || {},
    alias: _91_46_46_46token_93E51lbisvWWMeta?.alias || [],
    redirect: _91_46_46_46token_93E51lbisvWWMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93E51lbisvWWMeta?.name ?? "authorization-social-token___ru",
    path: _91_46_46_46token_93E51lbisvWWMeta?.path ?? "/ru/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93E51lbisvWWMeta || {},
    alias: _91_46_46_46token_93E51lbisvWWMeta?.alias || [],
    redirect: _91_46_46_46token_93E51lbisvWWMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: indexsqeiX8pM52Meta?.name ?? "authorization___en___default",
    path: indexsqeiX8pM52Meta?.path ?? "/authorization",
    meta: indexsqeiX8pM52Meta || {},
    alias: indexsqeiX8pM52Meta?.alias || [],
    redirect: indexsqeiX8pM52Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexsqeiX8pM52Meta?.name ?? "authorization___en",
    path: indexsqeiX8pM52Meta?.path ?? "/en/authorization",
    meta: indexsqeiX8pM52Meta || {},
    alias: indexsqeiX8pM52Meta?.alias || [],
    redirect: indexsqeiX8pM52Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexsqeiX8pM52Meta?.name ?? "authorization___ru",
    path: indexsqeiX8pM52Meta?.path ?? "/ru/authorization",
    meta: indexsqeiX8pM52Meta || {},
    alias: indexsqeiX8pM52Meta?.alias || [],
    redirect: indexsqeiX8pM52Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexIA14BUwnugMeta?.name ?? "cabinet-api___en___default",
    path: indexIA14BUwnugMeta?.path ?? "/cabinet/api",
    meta: indexIA14BUwnugMeta || {},
    alias: indexIA14BUwnugMeta?.alias || [],
    redirect: indexIA14BUwnugMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexIA14BUwnugMeta?.name ?? "cabinet-api___en",
    path: indexIA14BUwnugMeta?.path ?? "/en/cabinet/api",
    meta: indexIA14BUwnugMeta || {},
    alias: indexIA14BUwnugMeta?.alias || [],
    redirect: indexIA14BUwnugMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexIA14BUwnugMeta?.name ?? "cabinet-api___ru",
    path: indexIA14BUwnugMeta?.path ?? "/ru/cabinet/api",
    meta: indexIA14BUwnugMeta || {},
    alias: indexIA14BUwnugMeta?.alias || [],
    redirect: indexIA14BUwnugMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: index2NNaIA0Y3LMeta?.name ?? "cabinet___en___default",
    path: index2NNaIA0Y3LMeta?.path ?? "/cabinet",
    meta: index2NNaIA0Y3LMeta || {},
    alias: index2NNaIA0Y3LMeta?.alias || [],
    redirect: index2NNaIA0Y3LMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: index2NNaIA0Y3LMeta?.name ?? "cabinet___en",
    path: index2NNaIA0Y3LMeta?.path ?? "/en/cabinet",
    meta: index2NNaIA0Y3LMeta || {},
    alias: index2NNaIA0Y3LMeta?.alias || [],
    redirect: index2NNaIA0Y3LMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: index2NNaIA0Y3LMeta?.name ?? "cabinet___ru",
    path: index2NNaIA0Y3LMeta?.path ?? "/ru/cabinet",
    meta: index2NNaIA0Y3LMeta || {},
    alias: index2NNaIA0Y3LMeta?.alias || [],
    redirect: index2NNaIA0Y3LMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexEV5VESRwcHMeta?.name ?? "cabinet-numbers-history___en___default",
    path: indexEV5VESRwcHMeta?.path ?? "/cabinet/numbers-history",
    meta: indexEV5VESRwcHMeta || {},
    alias: indexEV5VESRwcHMeta?.alias || [],
    redirect: indexEV5VESRwcHMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexEV5VESRwcHMeta?.name ?? "cabinet-numbers-history___en",
    path: indexEV5VESRwcHMeta?.path ?? "/en/cabinet/numbers-history",
    meta: indexEV5VESRwcHMeta || {},
    alias: indexEV5VESRwcHMeta?.alias || [],
    redirect: indexEV5VESRwcHMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexEV5VESRwcHMeta?.name ?? "cabinet-numbers-history___ru",
    path: indexEV5VESRwcHMeta?.path ?? "/ru/cabinet/numbers-history",
    meta: indexEV5VESRwcHMeta || {},
    alias: indexEV5VESRwcHMeta?.alias || [],
    redirect: indexEV5VESRwcHMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1rIPbNh7RMeta?.name ?? "cabinet-payments___en___default",
    path: indexi1rIPbNh7RMeta?.path ?? "/cabinet/payments",
    meta: indexi1rIPbNh7RMeta || {},
    alias: indexi1rIPbNh7RMeta?.alias || [],
    redirect: indexi1rIPbNh7RMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1rIPbNh7RMeta?.name ?? "cabinet-payments___en",
    path: indexi1rIPbNh7RMeta?.path ?? "/en/cabinet/payments",
    meta: indexi1rIPbNh7RMeta || {},
    alias: indexi1rIPbNh7RMeta?.alias || [],
    redirect: indexi1rIPbNh7RMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1rIPbNh7RMeta?.name ?? "cabinet-payments___ru",
    path: indexi1rIPbNh7RMeta?.path ?? "/ru/cabinet/payments",
    meta: indexi1rIPbNh7RMeta || {},
    alias: indexi1rIPbNh7RMeta?.alias || [],
    redirect: indexi1rIPbNh7RMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: index6FkM4pJ9IEMeta?.name ?? "cabinet-profile___en___default",
    path: index6FkM4pJ9IEMeta?.path ?? "/cabinet/profile",
    meta: index6FkM4pJ9IEMeta || {},
    alias: index6FkM4pJ9IEMeta?.alias || [],
    redirect: index6FkM4pJ9IEMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index6FkM4pJ9IEMeta?.name ?? "cabinet-profile___en",
    path: index6FkM4pJ9IEMeta?.path ?? "/en/cabinet/profile",
    meta: index6FkM4pJ9IEMeta || {},
    alias: index6FkM4pJ9IEMeta?.alias || [],
    redirect: index6FkM4pJ9IEMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index6FkM4pJ9IEMeta?.name ?? "cabinet-profile___ru",
    path: index6FkM4pJ9IEMeta?.path ?? "/ru/cabinet/profile",
    meta: index6FkM4pJ9IEMeta || {},
    alias: index6FkM4pJ9IEMeta?.alias || [],
    redirect: index6FkM4pJ9IEMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexohVdntkSTPMeta?.name ?? "forgot-password___en___default",
    path: indexohVdntkSTPMeta?.path ?? "/forgot-password",
    meta: indexohVdntkSTPMeta || {},
    alias: indexohVdntkSTPMeta?.alias || [],
    redirect: indexohVdntkSTPMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexohVdntkSTPMeta?.name ?? "forgot-password___en",
    path: indexohVdntkSTPMeta?.path ?? "/en/forgot-password",
    meta: indexohVdntkSTPMeta || {},
    alias: indexohVdntkSTPMeta?.alias || [],
    redirect: indexohVdntkSTPMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexohVdntkSTPMeta?.name ?? "forgot-password___ru",
    path: indexohVdntkSTPMeta?.path ?? "/ru/forgot-password",
    meta: indexohVdntkSTPMeta || {},
    alias: indexohVdntkSTPMeta?.alias || [],
    redirect: indexohVdntkSTPMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: index6eej0h6p7JMeta?.name ?? "index___en___default",
    path: index6eej0h6p7JMeta?.path ?? "/",
    meta: index6eej0h6p7JMeta || {},
    alias: index6eej0h6p7JMeta?.alias || [],
    redirect: index6eej0h6p7JMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index6eej0h6p7JMeta?.name ?? "index___en",
    path: index6eej0h6p7JMeta?.path ?? "/en",
    meta: index6eej0h6p7JMeta || {},
    alias: index6eej0h6p7JMeta?.alias || [],
    redirect: index6eej0h6p7JMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index6eej0h6p7JMeta?.name ?? "index___ru",
    path: index6eej0h6p7JMeta?.path ?? "/ru",
    meta: index6eej0h6p7JMeta || {},
    alias: index6eej0h6p7JMeta?.alias || [],
    redirect: index6eej0h6p7JMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index8vIkxF97sXMeta?.name ?? "payment-result___en___default",
    path: index8vIkxF97sXMeta?.path ?? "/payment/result",
    meta: index8vIkxF97sXMeta || {},
    alias: index8vIkxF97sXMeta?.alias || [],
    redirect: index8vIkxF97sXMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: index8vIkxF97sXMeta?.name ?? "payment-result___en",
    path: index8vIkxF97sXMeta?.path ?? "/en/payment/result",
    meta: index8vIkxF97sXMeta || {},
    alias: index8vIkxF97sXMeta?.alias || [],
    redirect: index8vIkxF97sXMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: index8vIkxF97sXMeta?.name ?? "payment-result___ru",
    path: index8vIkxF97sXMeta?.path ?? "/ru/payment/result",
    meta: index8vIkxF97sXMeta || {},
    alias: index8vIkxF97sXMeta?.alias || [],
    redirect: index8vIkxF97sXMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexYF625XkeFQMeta?.name ?? "registration___en___default",
    path: indexYF625XkeFQMeta?.path ?? "/registration",
    meta: indexYF625XkeFQMeta || {},
    alias: indexYF625XkeFQMeta?.alias || [],
    redirect: indexYF625XkeFQMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexYF625XkeFQMeta?.name ?? "registration___en",
    path: indexYF625XkeFQMeta?.path ?? "/en/registration",
    meta: indexYF625XkeFQMeta || {},
    alias: indexYF625XkeFQMeta?.alias || [],
    redirect: indexYF625XkeFQMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexYF625XkeFQMeta?.name ?? "registration___ru",
    path: indexYF625XkeFQMeta?.path ?? "/ru/registration",
    meta: indexYF625XkeFQMeta || {},
    alias: indexYF625XkeFQMeta?.alias || [],
    redirect: indexYF625XkeFQMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93eDOYFhPg1BMeta?.name ?? "reset-password-token___en___default",
    path: _91_46_46_46token_93eDOYFhPg1BMeta?.path ?? "/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93eDOYFhPg1BMeta || {},
    alias: _91_46_46_46token_93eDOYFhPg1BMeta?.alias || [],
    redirect: _91_46_46_46token_93eDOYFhPg1BMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93eDOYFhPg1BMeta?.name ?? "reset-password-token___en",
    path: _91_46_46_46token_93eDOYFhPg1BMeta?.path ?? "/en/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93eDOYFhPg1BMeta || {},
    alias: _91_46_46_46token_93eDOYFhPg1BMeta?.alias || [],
    redirect: _91_46_46_46token_93eDOYFhPg1BMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93eDOYFhPg1BMeta?.name ?? "reset-password-token___ru",
    path: _91_46_46_46token_93eDOYFhPg1BMeta?.path ?? "/ru/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93eDOYFhPg1BMeta || {},
    alias: _91_46_46_46token_93eDOYFhPg1BMeta?.alias || [],
    redirect: _91_46_46_46token_93eDOYFhPg1BMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241112111352/pages/reset-password/[...token].vue").then(m => m.default || m)
  }
]